$(function () {

    // set locale
    $.datetimepicker.setLocale('ja');

    // init
    initDatetimepicker();

    // hanlder click icon
    handleClickIcon();
});

function initDatetimepicker() {
    // init datetimepicker
    let datetimepicker = $('.datetimepicker');

    $.each(datetimepicker, function (index, element) {
        let value = element.dataset.value ?? '';
        let format = element.dataset.format ?? '';
        let timepicker = element.dataset.timepicker !== 'false';
        let allowChoosePastTime = element.dataset.allow_choose_past_time === 'true';
        let option = {};

        if (value.length !== 0) {
            option.value = value;
        } else if (format.length !== 0) {
            option.format = format;
        }
        option.timepicker = timepicker;

        let date = new Date();
        if (!allowChoosePastTime) {
            option.minDate = date.getTime();
            option.minTime = date.getHours() + ':' + date.getMinutes();
        }

        $('.datetimepicker[name="' + element.name + '"]').datetimepicker(option);
    });
}

function handleClickIcon() {
    let datetimepickerIcon = $('.datetimepicker-icon');

    $(document).on('click', '.datetimepicker-icon', function () {
        $(this).prev().datetimepicker('toggle');
        // console.log($(this).prev().datetimepicker('getValue'));
    });
}
