$(function (e) {
    window.onpageshow = function(event) {
        if (event.persisted) {
            window.location.reload();
        }
    };

    $('form').on('submit', function (e) {
        if ($('label.error').length === 0) {
            $('#loading-overlay').show();
        }
    });
});
