$(document).on('click', '.custom-image-file-label', function () {
    $(this).closest('.custom-image-file').find('.custom-image-file-input').click();
})

$(document).on('change', '.custom-image-file-input', function () {
    const file = this.files;
    if (file.length > 0) {
        const fileName = file[0].name;
        const parentEl = $(this).parents('.image-wrapper');
        const companyImageEls = $('.company-image').length;
        parentEl.find('.file-name').text(fileName);
        if (companyImageEls > 1) {
            $('.company-image-wrapper .custom-image-file-input').each(function () {
                const input = $(this);
                if (!input.val()) return;
                input.closest('.image-box').find('.delete-file').removeClass('d-none');
            })
        }
    }
})

$(document).on('click', '.delete-file', function (e) {
    e.preventDefault();
    const parentEl = $(this).parents('.image-wrapper');
    parentEl.remove();
    const companyImageEls = $('.company-image').length;
    if (companyImageEls === 1) {
        $('.image-wrapper').find('.delete-file').addClass('d-none');
    }

    $('.add-company-image').removeClass('pointer-events-none')
})

$(document).on('change', '.trim-input', function () {
    const value = $(this).val().trim();

    $(this).val(value)
})

$(document).on('keypress', '.number-input', function (e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
})

$(document).on('keypress', '.no-space', function (e) {
    if(e.which === 32) return false;
})