$(function () {

  // set value limit record
  setValueLimitRecord();

  // handle click limit record
  handleClickLimitRecord();

  // get value sort in url
  getValueSortInUrl();

  // handleClickIconSort
  handleClickIconSort();

});


// handle limit record

let currentUrl = $(location).attr('href');
let url = new URL(currentUrl);
let limitUrl = url.searchParams.get('per_page') || 10;

function setValueLimitRecord() {
  $('#per_page').val(limitUrl);
}

function handleClickLimitRecord() {
  $('.per-page').on('change', function() {
    let limit = $('.per-page option:selected').val();
    if (currentUrl.indexOf('per_page') !== -1) {
      var href = new URL(currentUrl);
      href.searchParams.set('page', '1');

      let redirectUrl = href.toString().replace('per_page=' + limitUrl, 'per_page=' + limit);
      window.location = redirectUrl;
    } else {
      let href = new URL(currentUrl);
      href.searchParams.set('page', '1');

      href += (href.toString().indexOf('?') !== -1) ? '&per_page=' : '?per_page='
      href += limit;

      window.location = href;
    }
  });
}

let sortInput = url.searchParams.get('sort');

function getValueSortInUrl() {
  if (sortInput) {
    let sortArray = sortInput.split(',').filter(Boolean);
    sortArray.forEach(function(value, index) {
      if (value.indexOf('-') !== -1) {
        let dataInput = value.split('-');
        $('.parent_' + dataInput[1]).attr('aria-sort', 'descending');
      } else {
        $('.parent_' + value).attr('aria-sort', 'ascending');
      }
    });
    $('#sort').val(sortArray.join());
    url.searchParams.set('sort', sortArray.join());
  }
}

function handleClickIconSort() {
  $('.sort').on('click', function() {
    let currentButtonSort = $(this).attr('aria-sort');

    // none -> asc -> desc -> none
    switch (currentButtonSort) {
      case 'none':
        sortASC($(this));
        break;
      case 'ascending':
        sortDESC($(this));
        break;
      case 'descending':
        sortNone($(this));
        break;
    }
  });
}

function sortASC(elementSort) {
  elementSort.attr('aria-sort', 'ascending');
  fieldSort = elementSort.find('input').val();
  if (sortInput === '') {
    window.location = currentUrl.replace('sort=', 'sort=' + fieldSort);
  } else {
    if (currentUrl.indexOf('sort') !== -1) {
      var regex = /[?&]([^=#]+)=([^&#]*)/g,
          params = {},
          match;
      while(match = regex.exec(currentUrl)) {
        params[match[1]] = match[2];
      }
      window.location = currentUrl.replace('sort=' + params['sort'], 'sort=' + params['sort'] + ',' + fieldSort);
    } else {
      let href = currentUrl;
      href += (currentUrl.indexOf('?') !== -1) ? '&sort=' : '?sort=';
      href += fieldSort;

      window.location = href;
    }
  }
}

function sortDESC(elementSort) {
  elementSort.attr('aria-sort', 'descending');
  fieldSort = elementSort.find('input').val();
  if (currentUrl.indexOf('sort') !== -1) {
    if (sortInput.indexOf(fieldSort) !== -1) {
      window.location = currentUrl.replace(new RegExp('(.*)' + fieldSort), "$1-" + fieldSort);
    } else {
      window.location = currentUrl.replace('sort=' + sortInput, 'sort=' + sortInput + ',' + '-' + fieldSort);
    }
  }
}

function sortNone(elementSort) {
  elementSort.attr('aria-sort', 'none');
  fieldSort = elementSort.find('input').val();
  let href = currentUrl.replace(fieldSort, '');
  href = currentUrl.replace('-' + fieldSort, '');
  if (href.indexOf('sort=,')) {
    href = href.replace('sort=,', 'sort=');
  }
  if (href.indexOf(',,')) {
    href = href.replace(',,', ',');
  }
  if (href.substring(href.length - 1) === ',') {
    href = href.slice(0, -1);
  }

  window.location = href;
}
